@font-face {
  font-family: "Satoshi";
  src: local(Satoshi), url(./assets/Satoshi-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Satoshi";
  src: local(Satoshi), url(./assets/Satoshi-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Satoshi";
  src: local(Satoshi), url(./assets/Satoshi-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Satoshi";
  src: local(Satoshi), url(./assets/Satoshi-Black.ttf) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Chillax";
  src: local(Chillax), url(./assets/Chillax-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Chillax";
  src: local(Chillax), url(./assets/Chillax-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Chillax";
  src: local(Chillax), url(./assets/Chillax-Semibold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Chillax";
  src: local(Chillax), url(./assets/Chillax-Bold.ttf) format("truetype");
  font-weight: 700;
}
